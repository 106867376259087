<template lang="pug">
  .FormDocumentsAdd
    form-documents-add-html(
      :documentNumber="documentNumber"
      :loading="loading"
      :closeFullscreen="closeFullscreen"
      :openCropper="openCropper"
      :fullscreenOpened="fullscreenOpened"
      :handleCreateImage="handleCreateImage"
      :rotate="rotate"
      :urlData="urlData"
      :errorModal="errorModal"
      :loadingModal="loadingModal"
      :getHandleCloseButton="getHandleCloseButton"
      :closeModal="closeModal"
      :showModal="showModal"
      :handleChangeFile="handleChangeFile"
      :files="files"
      :handleContinue="handleContinue"
      @change_model_doc="changeDocumentModel"
      :deleteFile="deleteFile"
      :typeImage="typeImage"
      :fileNoImage="fileNoImage"
    )
</template>

<script>
import { mapActions, mapState } from "vuex";
import uniqid from "uniqid";
import compressorGenerator from "../helpers/compressor";
import loadingMessage from '../utils/loadingMessage';

export default {
  name: "FormDocumentsAdd",
  data() {
    return {
      files: [],
      file: null,
      handleCloseButton: null,
      documentNumber: "",
      urlData: "",
      loading: false,
      showModal: false,
      fullscreenOpened: true,
      rotationDeg: 2,
      loadingModal: false,
      errorModal: "",
      cropper: null,
      typeImage: true,
      fileNoImage: null,
    };
  },
  components: {
    FormDocumentsAddHtml: () => import("./FormDocumentsAddHTML.vue"),
  },
  created() {
    if (this.formSelectedForEdit) {
      this.files = this.formSelectedForEdit.files;
    }
  },
  computed: {
    ...mapState({
      formDocuments: (state) => state.formDocuments.formDocumentsState,
      userData: (state) => state.user.userData,
      formSelectedForEdit: (state) => state.formDocuments.formSelectedForEdit,
      eps: (state) => state.eps.epsState,
      external_token: (state) => state.environment.environment.external_token,
      formDocumentsFinalState: (state) => state.formDocuments.formDocumentsFinalState,
      env: (state) => state.environment.environment.env,
      userDataCheckPoints: (state) => state.root.userData,
      closeModalRoot: (state) => state.root.closeModal,
    }),
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal",
    ]),
    ...mapActions("formDocuments", [
      "setFormDocuments",
      "setLoading",
      "handleContinueDocuments",
    ]),
    ...mapActions("drugstore", [
      "fetchDrugstoresList",
    ]),
    closeModal() {
      this.showModal = false;
    },
    changeDocumentModel(val) {
      this.documentNumber = val;
    },
    async handleChangeFile(event) {
      this.typeImage = true;
      const errorResponse = { buttonText: "Entendido", disabledContinue: false, showModal: true, responseState: "danger", };
      const files = event.target.files || event.dataTransfer.files;
      const regexImages = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i);

      if (!files.length) {
        return;
      }

      const sizeInMB = (files[0].size / (1024 * 1024)).toFixed(2);
      if (sizeInMB >= 8.5) {
        this.sendImageSizeError(errorResponse);
        return;
      }

      if (!regexImages.test(files[0].name)) {
        this.showModal = true;
        this.fileNoImage = files[0];
        this.file = files[0];
        this.typeImage = regexImages.test(files[0].name);
        return;
      }

      compressorGenerator({
        file: files[0],
        queality: 0.4,
        successCb: async (result) => {
          this.file = result;
          const objectUrl = URL.createObjectURL(result);

          this.showModal = true;
          this.urlData = objectUrl;
        },
        errorCb: (error) => {
          console.error(error);
          this.handleShowModal({
            showModal: true,
            modalState: {
              ...errorResponse,
              responseToContinueTitle: "Error al comprimir tu archivo",
              responseToContinue: `
                Por favor vuelve a intentarlo.
              `
            },
          });
        },
      });
    },
    createFile(file, fileReal) {
      const regexImages = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i);
      const isImage = regexImages.test(fileReal.name);

      if (isImage) {
        let objectUrl = file;
        const condition = typeof file !== "string";
  
        if (condition) {
          objectUrl = URL.createObjectURL(file);
        }
        const files = [];
        const newFile = condition ? {
          name: file.name,
          type: file.match(/[^:/]\w+(?=;|,)/)[0],
          ...file,
          objectUrl,
          id: `${uniqid()}_image`,
        } : {
          name: this.file.name,
          type: file.match(/[^:/]\w+(?=;|,)/)[0],
          ...this.file,
          objectUrl,
          id: `${uniqid()}_image`,
        };
        files.push(newFile);
        this.files = [
          ...this.files,
          ...files,
        ];
      } else {
        const files = [];
        const newFile = {
          name: fileReal.name,
          type: fileReal.type,
          objectUrl: fileReal,
          id: `${uniqid()}_image`,
        };
        files.push(newFile);
        this.files = [
          ...this.files,
          ...files,
        ];
      }

      const timer = setTimeout(() => {
        const $main = document.querySelector("html");
        $main.scrollTo(0, $main.scrollHeight);
        clearTimeout(timer);
      }, 1000);
    },
    sendImageSizeError(errorResponse) {
      this.handleShowModal({
        showModal: true,
        modalState: {
          ...errorResponse,
          responseToContinueTitle: "Error al subir el archivo",
          responseToContinue: `
            Por favor suba un archivo que pese menos de 10MB.
          `
        },
      });
    },
    handleContinue() {
      this.loading = true;
      // if (!this.documentNumber || !this.files || !this.files.length) {
      //   this.handleShowModal({
      //     showModal: true,
      //     modalState: {
      //       buttonText: "Entendido",
      //       disabledContinue: false,
      //       showModal: true,
      //       responseState: "danger",
      //       responseToContinueTitle: "Error al digitar tus datos",
      //       responseToContinue: `
      //         Todos los datos son requeridos para continuar, por favor llénalos.
      //       `
      //     },
      //   });
      //   this.loading = false;
      //   console.error("Error al digitar datos, falta informacion");
      //   return;
      // }

      if(!this.documentNumber){

        this.setLoading(true);
        this.handleShowModal(loadingMessage({
          title: "Buscando puntos de dispensación...",
          state: "loading",
        }));
          
        // Send to check files on server
        setTimeout(async () => {
          
          await this.handleContinueDocuments({
            data: {
              formulas: 9999999999999,
              tipo_documento: this.userDataCheckPoints.dcType,
              numero_documento: this.userDataCheckPoints.documentNumber,
              ciudad_id: this.userDataCheckPoints.cityId,
              eps_id: this.eps.code
            },
            url: this.env.VUE_APP_EXTERNAL_SERVER + '/search-dispensing-points/client/1',
            token: this.external_token.access_token,
          }).catch((error) => {
            console.error(error);
            this.handleShowModal(loadingMessage({
              title: "Hubo un error al validar tus fórmulas",
              state: "danger",
            }));
          });

          console.log(this.formDocumentsFinalState)
          
          if(!this.formDocumentsFinalState){
            return this.handleShowModal(loadingMessage({
              title: "Hubo un error al validar tus fórmulas",
              state: "danger",
            }));
          }

          this.setFormDocuments(this.formDocumentsFinalState);
          
          await this.fetchDrugstoresList();
          this.setLoading(false);
          this.closeModal();
          this.closeModalRoot();
          this.changeTrip("Home_appointments"); 
          

        }, 1000);

        return
      
      }

      if (!this.formSelectedForEdit) {
        this.setFormDocuments([
          ...this.formDocuments,
          {
            numero: this.documentNumber,
            resultado: Math.floor(Math.random() * 6) > 3 ? 5 : 2,
            resultado_descripcion: "falla en el servicio",
            files: this.files,
            id: uniqid(),
            index: this.formDocuments.length,
          }
        ]);
      } else {
        let filteredForm = this.formDocuments && this.formDocuments.length ?
          JSON.parse(JSON.stringify(this.formDocuments.filter((ff) => ff.id !== this.formSelectedForEdit.id))) : [];

        filteredForm.push({
          numero: this.documentNumber,
          resultado: Math.floor(Math.random() * 6) > 3 ? 5 : 2,
          resultado_descripcion: "falla en el servicio",
          files: this.files,
          id: this.formSelectedForEdit.id,
          index: this.formDocuments.length,
        });

        this.setFormDocuments(filteredForm || []);
      }
      // if (this.userData.dates && this.userData.dates.length) {
      //   this.changeTrip("FormDocumentsDate");
      //   return;
      // }
      this.changeTrip("FormDocuments");
    },
    handleCloseModalButton() {
      this.handleCloseButton();
    },
    getHandleCloseButton(handleCloseButton) {
      this.handleCloseButton = handleCloseButton;
    },
    handleCreateImage() {
      this.closeModal();
      this.createFile(this.urlData, this.file);
    },
    openCropper(newUrl) {
      this.urlData = newUrl;
    },
    closeFullscreen() {
      this.fullscreenOpened = true;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    },
    rotate(cropper, cb) {
      cropper.rotate(90);
      cb();
    },
    deleteFile(file) {
      if (this.files.length === 1) {
        this.files = [];
        return;
      }
      const filteredFiles = this.files.filter((fileScope) => fileScope.id !== file.id);

      this.files = filteredFiles;
    }
  },
}
</script>
import Compressor from "compressorjs";

const compressorGenerator = ({
  file,
  quality = 0.6,
  errorCb,
  successCb,
  options = {},
}) => {
  new Compressor(file, {
    quality,
    success(result) {
      successCb(result);
    },
    error(err) {
      errorCb(err);
    },
    ...options,
  });
};

export default compressorGenerator;
